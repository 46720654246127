// // UploadChapter.jsx
// import React, { useContext, useEffect, useState } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Paper, Container, Stack, Typography, Button, Card, TablePagination } from '@mui/material';
// import { useQuestionContext } from '../../context/QuestionPaperContext';
// import Iconify from '../../components/iconify';
// import UserTableToolbar from '../user/user-table-toolbar';
// import Scrollbar from '../../components/scrollbar';
// import UserTableHead from '../user/user-table-head';
// import UserTableRow from '../user/user-table-row';
// import TableEmptyRows from '../user/table-empty-rows';
// import TableNoData from '../user/table-no-data';

// import { emptyRows, applyFilter, getComparator } from './utils';



import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  IconButton,
  Button,
  Card,
  Stack,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Iconify from '../../components/iconify';
import {useQuestionContext} from "../../context/QuestionPaperContext"
import AddChapter from './add_chapter';
import axios from 'axios';

// Sample data
// const initialData = [
//   { id: 1, chapter: 'Chapter 1', subject: 'Subject A' },
//   { id: 2, chapter: 'Chapter 2', subject: 'Subject B' },
//   // Add more data here
// ];

const UploadChapter = () => {
    const { GetChapterList,DeleteChapter,getSubjectList } = useQuestionContext();
    const [data, setData] = useState([]); 
     const [subject, setSubject] = useState([]);
  const [page, setPage] = useState(0);;
  const [openC, setOpenC] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

const fetchChapter=async()=>{
  let mydata=await GetChapterList()
  console.log("aa",mydata)
  setData(mydata)
}

  useEffect(()=>{
fetchChapter()
  },[])

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
  setPage(0);
};

const handleEdit = (id) => {
  console.log('Edit item with id:', id);
  // Implement edit logic here
  // setOpenC(true)
};

const handleDeleteClick = async(id) => {
  // Implement delete logic here
let returnVal=await DeleteChapter(id)
// console.log(returnVal)
if(returnVal=="Success"){

  // setData(returnVal.newResult);
 fetchChapter()
}


};
// console.log(data[0].Chapter&&data[0].Chapter,searchTerm)
// console.log( data.map(item =>item.Chapter),searchTerm,data&&data[1].Chapter.includes(searchTerm));
const filteredData = searchTerm===""?data:data&&data.filter((item) => {
  console.log(JSON.stringify(item),item.Chapter.toLowerCase(),searchTerm.toLowerCase())
  let v=item.Chapter.toLowerCase().includes(searchTerm&&searchTerm.toLowerCase()) ||
  item.Subject.toLowerCase().includes(searchTerm&&searchTerm.toLowerCase())
  console.log(item.Chapter.toLowerCase().includes(searchTerm.toLowerCase()) ||
  item.Subject.toLowerCase().includes(searchTerm.toLowerCase()))
  return v
}
);


const handleAddChapterClick=async() => {
let subjectList=await getSubjectList()
console.log(subjectList)
  setSubject(subjectList)
setOpenC(true)
}

const handleAddChapter=async(newChapter)=>{
console.log(newChapter)
  const res = await axios.post(
    "https://testseriesapi.innoinfotech.in/api/questionPaper/addChapter",
   {
    chapter: newChapter.chapter,
    subject:newChapter.subject
   }
  );
  if (res.data.message == "Success") {
    // setData(res.data.result);
    fetchChapter()
  }
}

return (
  <Paper>
     <Stack direction="row" alignItems="center" justifyContent="space-between" mr={5} mt={3} mb={3} ml={5}>
        <Typography variant="h4">Chapter</Typography>

        <Button variant="contained" color="inherit" onClick={handleAddChapterClick} startIcon={<Iconify icon="eva:plus-fill" />}>
          New Chapter
        </Button>
      </Stack>
      <AddChapter
        open={openC}
        setOpen={setOpenC}
        handleSubmit={handleAddChapter}
        subject={subject}
      />
    <TextField
      label="Search"
      variant="outlined"
      value={searchTerm}
      onChange={handleSearchChange}
      style={{ margin: '16px' }}
      />
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sr No</TableCell>
           
            <TableCell>Subject</TableCell>
            <TableCell>Chapter</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {console.log()}
          {filteredData&&filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow key={row.SrNo}>
              <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
              <TableCell>{row.Chapter}</TableCell>
              <TableCell>{row.Subject}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(row.SrNo)} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(row.SrNo)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={filteredData&&filteredData.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
);
};

export default UploadChapter;

//   const [filter, setFilter] = useState('');
//   const [chapter, setChapter] = useState([]);

//   const [page, setPage] = useState(0);

//   const [order, setOrder] = useState('asc');

//   const [selected, setSelected] = useState([]);

//   const [orderBy, setOrderBy] = useState('name');

//   const [filterName, setFilterName] = useState('');

//   const [rowsPerPage, setRowsPerPage] = useState(5);


// const fetchChapter=async()=>{
//   let chapter=await GetChapterList()
//   setChapter(chapter)
// }
//   useEffect(()=>{
//     fetchChapter()
//   },[])
//   // Filter data based on the filter value
//   const filteredData = chapter&&chapter.filter(item =>
//     item.chapter.toLowerCase().includes(filter.toLowerCase()) ||
//     item.subject.toLowerCase().includes(filter.toLowerCase())
//   );

//   const handleSort = (event, id) => {
//     const isAsc = orderBy === id && order === 'asc';
//     if (id !== '') {
//       setOrder(isAsc ? 'desc' : 'asc');
//       setOrderBy(id);
//     }
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = chapter&&chapter.map((n) => n.name);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name);
//     let newSelected = [];
//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selected.slice(0, selectedIndex),
//         selected.slice(selectedIndex + 1)
//       );
//     }
//     setSelected(newSelected);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setPage(0);
//     setRowsPerPage(parseInt(event.target.value, 10));
//   };

//   const handleFilterByName = (event) => {
//     setPage(0);
//     setFilterName(event.target.value);
//   };
//   const dataFiltered = chapter&&applyFilter({
//     inputData: chapter,
//     comparator: getComparator(order, orderBy),
//     filterName,
//   });

//   const notFound = !dataFiltered.length && !!filterName;
  
    
//   return (
//     <Container>
//     <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//       <Typography variant="h4">Chapter</Typography>

//       <Button variant="contained" color="inherit" startIcon={<Iconify icon="eva:plus-fill" />}>
//         Add Chapter
//       </Button>
//     </Stack>

//     <Card>
//         <UserTableToolbar
//           numSelected={selected.length}
//           filterName={filterName}
//           onFilterName={handleFilterByName}
//         />

//         <Scrollbar>
//           <TableContainer sx={{ overflow: 'unset' }}>
//             <Table sx={{ minWidth: 800 }}>
//               <UserTableHead
//                 order={order}
//                 orderBy={orderBy}
//                 rowCount={chapter.length}
//                 numSelected={selected.length}
//                 onRequestSort={handleSort}
//                 onSelectAllClick={handleSelectAllClick}
//                 headLabel={[
//                   { id: 'name', label: 'Name' },
//                   { id: 'email', label: 'Email' },
//                   { id: 'role', label: 'Role' },
                
//                   { id: 'action' ,label:"Action"},
//                 ]}
//               />
//               <TableBody>
//                 {/* <Suspense > */}
//                   {console.log("  ",dataFiltered)}
//                   {
                    
//   Array.isArray(dataFiltered)&&dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                       .map((row) => (
//                         <UserTableRow
//                           key={row.id}
//                           name={row.name}
//                           email={row.email}
//                           role={row.role}
//                           getchapter={fetchChapter}
//                           avatarUrl={row.avatarUrl}
//                           userId={row.id}
//                           selected={selected.indexOf(row.name) !== -1}
//                           handleClick={(event) => handleClick(event, row.name)}
//                         />
//                       ))
//                   }

//                 <TableEmptyRows
//                   height={77}
//                   emptyRows={emptyRows(page, rowsPerPage, chapter.length)}
//                 />

//                 {notFound && <TableNoData query={filterName} />}
//               {/* </Suspense> */}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Scrollbar>

//         <TablePagination
//           component="div"
//           count={chapter.length}
//           rowsPerPage={rowsPerPage}
//           onPageChange={handleChangePage}
//           rowsPerPageOptions={[5, 10, 25]}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Card>
//       <TextField
//         variant="outlined"
//         margin="normal"
//         fullWidth
//         label="Filter"
//         value={filter}
//         onChange={(e) => setFilter(e.target.value)}
//       />
//       <TableContainer>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Sr no</TableCell>
//               <TableCell>Chapter</TableCell>
//               <TableCell>Subject</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {filteredData&&filteredData.map((item) => (
//               <TableRow key={item.srno}>
//                 <TableCell>{item.srno}</TableCell>
//                 <TableCell>{item.chapter}</TableCell>
//                 <TableCell>{item.subject}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Container>
//   );